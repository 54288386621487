import Image from 'next/image'
import { useRouter } from 'next/navigation'
import { useMemo, useRef, useState, useCallback, useEffect, useContext } from 'react'
import { FiLogOut, FiUser } from 'react-icons/fi'

import type { ReactElement } from 'react'
import type { IconType } from 'react-icons'

import MenuItem from './MenuItem'

import Button, { VARIANT } from '~/components/Button'
import { tabletBreakpoint } from '~/const'
import { UserContext } from '~/contexts/UserContext'
import { useOnClickOutside } from '~/hooks/useOnClickOutside'
import { useWindowSize } from '~/hooks/useWindowSize'

import './style.scss'

interface INavBarLink {
  hidden?: boolean
  icon?: IconType
  name: string
  onClick: () => void
}

interface Props {
  email?: string
  hasPlan?: boolean
  isMenuOpened?: boolean
  isMobileSubscription?: boolean
  isSubscribed?: boolean
  onAuthButtonClick: () => void
  subscriptionType?: string
}

const NavbarMenu = ({ email, isMenuOpened: defaultMenuOpened, onAuthButtonClick }: Props): ReactElement => {
  const { width } = useWindowSize()

  const isTabletOrDesktop = width && width >= tabletBreakpoint

  const router = useRouter()

  const [isMenuOpened, setMenuOpen] = useState(defaultMenuOpened)

  const menuRef = useRef(null)

  const { logout } = useContext(UserContext)

  useOnClickOutside(menuRef, () => {
    if (isTabletOrDesktop) {
      setMenuOpen(false)
    }
  })

  const handleMenuClick = () => {
    setMenuOpen((state) => !state)
  }

  const handleRedirect = useCallback(
    (path: string) => {
      return () => {
        setMenuOpen(false)
        router.push(path)
      }
    },
    [setMenuOpen, router]
  )

  const handleLogout = useCallback(
    (path: string) => {
      return () => {
        logout()
        setMenuOpen(false)
        router.push(path)
      }
    },
    [router, setMenuOpen, logout]
  )

  const controlNavBarDisplay = useCallback(() => {
    setMenuOpen(false)
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', controlNavBarDisplay)
    return () => window.removeEventListener('scroll', controlNavBarDisplay)
  }, [controlNavBarDisplay])

  const userMenuDesktop: INavBarLink[] = useMemo(
    () => [
      {
        icon: FiUser,
        name: 'Account',
        onClick: handleRedirect('/account'),
      },
      {
        icon: FiLogOut,
        name: 'Log out',
        onClick: handleLogout('/'),
      },
    ],
    [handleRedirect, handleLogout]
  )

  const userMenuMobile: INavBarLink[] = useMemo(
    () => [
      {
        name: 'Documentation',
        onClick: handleRedirect('/documentation'),
      },
      {
        name: 'Account',
        onClick: handleRedirect('/account'),
      },
      {
        name: 'Contact us',
        onClick: () => window.open('mailto:contact@developer.mwm.ai', '_self'),
      },
    ],
    [handleRedirect]
  )

  return (
    <div className="navbar-menu-component" ref={menuRef}>
      {isTabletOrDesktop ? (
        email ? (
          <div className="navbar-menu-component__avatar" onClick={handleMenuClick}>
            {email.charAt(0).toUpperCase()}
          </div>
        ) : (
          <Button onClick={onAuthButtonClick} outline variant={VARIANT.SECONDARY}>
            Sign in / Sign up
          </Button>
        )
      ) : (
        <div className="navbar-menu-component__button" onClick={handleMenuClick}>
          <Image
            alt="hamburger menu button"
            height={24}
            src={isMenuOpened ? '/medias/menu-close-icon.svg' : '/medias/menu-icon.svg'}
            width={24}
          />
        </div>
      )}
      {isMenuOpened ? (
        <>
          <div className="navbar-menu-component__menu-container navbar-menu-component__menu-container--desktop">
            <div className="navbar-menu-component__menu-header">
              <div className="email">{email}</div>
            </div>
            {userMenuDesktop.map(({ icon, name, onClick }, index) => (
              <MenuItem icon={icon} key={index} name={name} onClick={onClick} />
            ))}
          </div>
          <div className="navbar-menu-component__menu-container navbar-menu-component__menu-container--mobile">
            <>
              {email ? (
                <>
                  <div className="navbar-menu-component__avatar" onClick={handleMenuClick}>
                    {email.charAt(0).toUpperCase()}
                  </div>
                  <div className="navbar-menu-component__email-wrapper">
                    <div className="navbar-menu-component__email">{email}</div>
                  </div>
                </>
              ) : null}
              {userMenuMobile.map(({ name, onClick }, index) => (
                <MenuItem key={index} name={name} onClick={onClick} />
              ))}
              {email ? (
                <>
                  <div className="navbar-menu-component__separator" />
                  <Button onClick={handleLogout('/')} variant={VARIANT.SECONDARY}>
                    Log out
                  </Button>
                </>
              ) : (
                <Button
                  className="navbar-menu-component__sign-up-button"
                  onClick={onAuthButtonClick}
                  variant={VARIANT.SECONDARY}
                >
                  Sign in / Sign up
                </Button>
              )}
            </>
          </div>
        </>
      ) : null}
    </div>
  )
}

export default NavbarMenu
