'use client'

import classNames from 'classnames'
import Link from 'next/link'
import { usePathname, useRouter } from 'next/navigation'
import { useContext, type ReactElement, useState, useEffect } from 'react'

import NavbarMenu from './Menu'

import MWMLogo from '../Logo'

import { tabletBreakpoint } from '~/const'
import { UserContext } from '~/contexts/UserContext'
import { useWindowSize } from '~/hooks/useWindowSize'

import './styles.scss'

interface Props {
  hidden?: boolean
}

const TopNavbar = ({ hidden }: Props): ReactElement => {
  /** Local state */

  const router = useRouter()

  const pathname = usePathname()

  const { width } = useWindowSize()

  const isTabletOrDesktop = width && width >= tabletBreakpoint

  const { account } = useContext(UserContext)

  const [activeTab, setActiveTab] = useState('/documentation')

  /** Handlers */

  const handleLogin = () => {
    router.push('/authentication?source=sign_in')
  }

  const handleTabClick = (path: string) => setActiveTab(path)

  const handleLogoClick = () => router.push('/')

  useEffect(() => {
    if (pathname) {
      setActiveTab(pathname)
    }
  }, [pathname])

  return (
    <div className={classNames('top-navbar-component', { hidden })}>
      <div className="top-navbar-component__content">
        <div className="top-navbar-component__content__navigation">
          <div className="logo-container" onClick={handleLogoClick}>
            <MWMLogo />
            <div className="logo-container__brand">
              <span className="logo-container__brand__text">Developer</span>
            </div>
          </div>
          {isTabletOrDesktop ? (
            <>
              <Link
                className={classNames('top-navbar-component__content__navigation__item', {
                  active: activeTab === '/documentation',
                })}
                href={'/documentation'}
                onClick={() => handleTabClick('/documentation')}
              >
                Documentation
              </Link>
              <a className="home-view__header__actions__link contact_link" href="mailto:contact@developer.mwm.ai">
                <span className="contact_label">Contact us</span>
              </a>
            </>
          ) : null}
        </div>
        <div className="top-navbar-component__content__button-container">
          <NavbarMenu email={account?.email.email} isMenuOpened={false} onAuthButtonClick={handleLogin} />
        </div>
      </div>
    </div>
  )
}

export default TopNavbar
