import classNames from 'classnames'

import type { ButtonHTMLAttributes, ReactElement, ReactNode } from 'react'

import ProgressCircle from './ProgressCircle'
import './styles.scss'

export const enum VARIANT {
  GHOST = 'ghost',
  PINK = 'pink',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode
  className?: string
  fullWidth?: boolean
  isLoading?: boolean
  outline?: boolean
  variant?: VARIANT
}

const Button = ({
  children,
  className,
  disabled,
  fullWidth,
  isLoading,
  onClick,
  outline,
  variant = VARIANT.PRIMARY,
  ...props
}: Props & ButtonHTMLAttributes<HTMLButtonElement>): ReactElement => {
  const buttonClasses = classNames('button-component', className, variant, {
    'button-component--loading': isLoading,
    'full-width': fullWidth,
    outline,
  })

  return (
    <button {...props} className={buttonClasses} disabled={disabled || isLoading} onClick={onClick}>
      <span className="button-component__label">{children}</span>
      {isLoading ? (
        <ProgressCircle
          className="button-component__progress-circle"
          isLoading={isLoading}
          strokeColor="#FFFFFF"
          trackColor="rgba(255, 255, 255, 0.3)"
        />
      ) : null}
    </button>
  )
}

export default Button
