import classnames from 'classnames'

import type { ReactElement } from 'react'
import type { IconType } from 'react-icons'

import './style.scss'

interface Props {
  icon?: IconType
  name?: string
  onClick: () => void
}

const MenuItem = ({ icon: Icon, name, onClick }: Props): ReactElement => {
  const menuItemClasses = classnames('menu-item-component')

  return (
    <div className={menuItemClasses} onClick={onClick}>
      {Icon ? <Icon className="menu-item-component__icon" size={24} /> : null}
      <div className="menu-item-component__text">{name}</div>
    </div>
  )
}

export default MenuItem
