import { useEffect } from 'react'

import type { RefObject } from 'react'

const useOnClickOutside = (ref: RefObject<HTMLElement>, handler: (evt: MouseEvent | TouchEvent) => void): void => {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      // @ts-expect-error Argument of type 'EventTarget | null' is not assignable to parameter of type 'Node | null'
      if (!ref.current || ref.current.contains(event.target)) {
        return
      }

      handler(event)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])
}

export { useOnClickOutside }
