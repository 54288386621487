import Image from 'next/image'

import type { ReactElement } from 'react'

import './styles.scss'

const MWMLogo = (): ReactElement => {
  return (
    <div className="mwm-logo">
      <Image alt="MWM logo" className="mwm-vector" height={15} src="/medias/mwm-logo-vector.svg" width={16} />
    </div>
  )
}

export default MWMLogo
